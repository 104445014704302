const toggleIsVisibleClass = (currStep, step, toggle) => {
  currStep.classList.toggle('is-visible', toggle);
  step.classList.toggle('is-visible', !toggle);
};

attachEvent('.btn--next', 'click', (el) => {
  const parentElem = el.target.closest('.game-module__step');
  const nextStep = parentElem.nextElementSibling;
  if (!parentElem.classList.contains('last-step')) {
    toggleIsVisibleClass(parentElem, nextStep, false);
  }
});

attachEvent('.btn--prev', 'click', (el) => {
  const parentElem = el.target.closest('.game-module__step');
  const prevStep = parentElem.previousElementSibling;
  toggleIsVisibleClass(parentElem, prevStep, false);
});
